(function ($) {
    $(document).ready(function () {
        // FILTER FORM SUBMIT HANDLER
        // FIXME иногда не срабатывает..... Не понятно почему.
        $('#filter-form').one('submit', function (e)
        {
            e.preventDefault();
            let brandVal = $('select[name=brand]').val();
            if (('undefined' !== typeof brandVal && brandVal) && parseInt(brandVal) !== 0)
            {
                let brandSlug = $('select[name=brand] option:selected').data("slug")
                let modelSlug = $('select[name=model] option:selected').data("slug")
                $('select[name=brand]').remove();

                let slug = brandSlug;
                if (modelSlug)
                    slug = brandSlug + '/' + modelSlug

                $(this).attr('action', '/' + window._cars_prefix + '/' + slug);


                let modelVal = $('select[name=model]').val();
                let yearVal = $('input[name=year]').val();
                let priceVal = $('input[name=price]').val();
                let transmissionVal = $('select[name=transmission]').val();
                $('select[name=model]').remove();

                if ((parseInt(modelVal) === 0 || !modelVal)
                    && (parseInt(transmissionVal) === 0 || !transmissionVal)
                    && yearVal === ''
                    && priceVal === '') {

                    window.location.href = '/' + window._cars_prefix + '/' + brandSlug;
                    return false;

                }
            }
            $(this).submit();
        });

        // CLIENT BRAND CHANGE HANDLER
        $('select[name=clientbrand]').on('change', function () {
            let selectedBrand = $(this).val();
            $.ajax({
                method: "GET",
                url: `/api/dictionary/get_model/${selectedBrand}` ,
                success: function (data) {
                    $('select[name=clientmodel]').html(data);
                }
            })
        })

        $('select[name=brand]').val("0").trigger('change');

        let transmissionInput = $('select[name=transmission]');

        if (transmissionInput.length > 0)
            $(transmissionInput).val(0).trigger('change');

        // BRAND INPUT CHANGE HANDLER
        $('select[name=brand]').on('change', function () {
            let selectedBrand = $('select[name=brand] option:selected').data("id")
            if (! window._allModels)
                return;
            let allModels = JSON.parse(window._allModels);

            if (window._allTransmissions && typeof window._allTransmissions !== 'object')
                window._allTransmissions = JSON.parse(window._allTransmissions);

            let parent = $(this).parents('form').first();
            let mainScreen = false;

            if (parent.length < 1)
            {
                parent = $(this).parents('.main-screen-selects').first();
                mainScreen = true;
            }

            let modelSelect = $('select[name=model]', parent)

            $(modelSelect)
                .find('option')
                .remove()
                .end()

            if ($(this).parents('.filters-screen').length)
            {
                $(modelSelect)
                    .append($("<option></option>")
                        .attr("value", "0")
                        .text('Любая'));
            }

            // FILL MODELS SELECT INPUT
            allModels.forEach(function (value, i, arr) {
                if (parseInt(selectedBrand) === parseInt(value.brand_id) || value.id === 0)
                {
                    if (value.transmissions)
                    {
                        $(modelSelect)
                            .append(
                                $("<option></option>")
                                    .attr("value", value.slug)
                                    .attr("data-slug", value.slug)
                                    .attr("data-transmissions", value.transmissions)
                                    .text(value.name)
                            );
                    }
                    else
                    {
                        $(modelSelect)
                            .append(
                                $("<option></option>")
                                    .attr("value", value.slug)
                                    .attr("data-slug", value.slug)
                                    .text(value.name)
                            );
                    }
                }
            });

            if (mainScreen)
            {
                let $options = $(modelSelect).find('option'),
                    random = ~~(Math.random() * $options.length);
                    $options.eq(random).prop('selected', true);

                $(modelSelect).trigger('change');
            }
            if (selectedBrand !== 0 && !mainScreen)
                $(modelSelect).val(allModels[0].slug).trigger('change');
        });


        $('select[name=model]').on('change', function ()
        {
            let transmissionSelect = $('select[name=transmission]');
            let transmissionsValues = $('select[name=model] option:selected').data('transmissions');
            if ($(transmissionSelect).length > 0 && 'undefined' !== typeof transmissionsValues)
            {
                $(transmissionSelect)
                    .find('option')
                    .remove()
                    .end()

                    let transmissionsArray = transmissionsValues.split("|");
                    transmissionsArray.unshift('Любая');

                    transmissionsArray.forEach(function (value, i, arr) {
                        $(transmissionSelect)
                            .append($("<option></option>")
                                .attr("value", window._allTransmissions[value])
                                .text(value));
                    });

                $('select[name="transmission"]').on('change', function()
                {
                    getCount();
                })
                $('select[name="transmission"]').val($('select[name="transmission"] option:first').val()).trigger('change');
            }


            let advSelect = $('select[name=advertisement]');
            if ($(advSelect).length > 0)
                getAdvertisementData($(this));
        });
    })

    function getAdvertisementData(modelSelect) {
        let model = $(modelSelect).select2('data');

        if (model.length < 1)
            return;
        $.ajax({
            method: "GET",
            url: `/api/cars/get_advertisements/${model[0].id}`,
            success: function (data) {
                let loadComboboxItems = new CustomEvent('loadComboboxItems', {'detail': data.data.items})
                document.dispatchEvent(loadComboboxItems);
            }
        })
    }

    function getCount() {
        let brand = $('select[name="brand"]').val();
        let model = $('select[name="model"]').val();
        let trans = $('select[name="transmission"]').val();
        $.ajax({
            method: "GET",
            url: `/api/cars/get_filters_count/0/?brand=${brand}&model=${model}&transmission=${trans}`,
            success: function (response)
            {
                // if (response === '0')
                //     $('select[name="transmission"]').val(0).trigger('change');

                $('#count-cars').text(response);
            }
        })
    }

})(jQuery);


