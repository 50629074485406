$(document).ready(function(){

	let menuItem = window.location.href;
	let liMenuTag = $(`header .menu a[href='${menuItem}']`).parent('li');
	$(liMenuTag[0]).addClass('active');

	$('[data-fancybox=""]').fancybox({
		autoFocus: false
	});


// mobile menu

	const header = document.querySelector(".header"),
		brandsBtn = document.querySelector(".brands-btn"),
		brandsIcon = document.querySelector(".brands-burger"),
		brandsMenu = document.querySelector(".brands-line");

	$('.header__top-line .burger').click(function () {
		$(this).toggleClass('active');
		$('.header__mobile-nav').slideToggle();
		$('body').toggleClass("dark");

		if ($(brandsIcon).hasClass('active'))
		{
			$(brandsIcon).removeClass("active");
			slideToggle(brandsMenu);
		}
	});

	$(document).mouseup(function (e) {
		if (!$('.header__top-line').is(e.target) &&
			$('.header__top-line').has(e.target).length === 0 && $('.burger').hasClass('active')) {
			$(".header__mobile-nav").fadeOut();
			$('body').removeClass("dark");
			$('.header__top-line .burger').removeClass('active');
		}
	});

	$('.footer__burger').click( function() {
		$('footer').find('.navbar').slideToggle(300);
	});


// brands menu



	var ww = window.innerWidth;

	function slideToggle(block) {
		if (!block.classList.contains('active')) {
			block.classList.add('active');
			block.style.height = 'auto';

			var height = block.clientHeight + 'px';
			block.style.height = '0px';

			setTimeout(function () {
				block.style.height = height;
			}, 0);

			block.addEventListener('transitionend',
				function () {
					block.style.height = 'auto';
				}, {
					once: true
				});
		} else {
			var height = block.clientHeight + 'px';
			block.style.height = height;

			setTimeout(function () {
				block.style.height = '0px';
			}, 0);

			block.addEventListener('transitionend',
				function () {
					block.classList.remove('active');
				}, {
					once: true
				});
		}
	}

	brandsBtn.addEventListener("click", function(event) {
		event.preventDefault();
		event.target.classList.toggle("active");
		slideToggle(brandsMenu);
	});

	brandsIcon.addEventListener("click", function(event) {
		event.preventDefault();
		event.target.classList.toggle("active");
		slideToggle(brandsMenu);

		if ($('.header__top-line .burger').hasClass('active'))
		{
			$('.header__top-line .burger').removeClass('active');
			$('.header__mobile-nav').slideToggle();
			$('body').removeClass("dark");
		}
	});

	window.addEventListener("resize", function(){
		let l=window.innerWidth;
		if(ww==l) return;
		ww=l;
		if (ww < 768) {
			brandsBtn.classList.remove('active');
			brandsIcon.classList.remove('active');
			brandsMenu.classList.remove('active');
		}
	});


// scroll events

	window.addEventListener( "scroll", function (event) {
		event.preventDefault();

		brandsBtn.classList.remove('active');
		brandsIcon.classList.remove('active');
		brandsMenu.classList.remove('active');

		let top = $(window).scrollTop();

		if ( top > 0) {
			header.classList.add('fixed');
			brandsMenu.classList.add('fixed');
		} else {
			header.classList.remove('fixed')
			brandsMenu.classList.remove('fixed');

			if ( ww > 767 && top < 100) {
				brandsBtn.classList.add('active');
				brandsIcon.classList.add('active');
				slideToggle(brandsMenu);
			}
		}

		if (top > 300) {
			progressView();
		}
	});


// hidden blocks

	$(".more-button").click(function(){
		$(this).closest('.more-parent').find('.more-info').slideDown(500);
		$(this).slideUp(0);
	});

	$(".filters-btn").click(function(){
		$(this).closest('.filters-screen').find('.form').slideToggle(500);
		$(this).toggleClass("active");
	});

	$(".more-fiters").click(function(){
		$(this).closest('.form').find('.form__row-more').slideToggle(500);
	});

// info plate

	$('.compare-screen li').hover(function(){
		let index = $(this).index();
		$('.compare-screen li').removeClass("hover");
		$(this).addClass("hover");
		$('.swiper-slide').each( function() {
			$(this).find("li").eq(index).addClass("hover");
		})
		$('.compare-screen__left-col li').eq(index).addClass("hover");
	});

	$('.compare-screen .car-plate__descr').hover(function(){
		let index = $(this).index();
		$('.compare-screen li').removeClass("hover");
		$('.compare-screen__left-col li:last-child').addClass("hover");
	});

// info plate

	window.detailsIcon = function () {
		$('.details-icon').hover(function(){
			$(this).closest('.details-wr').find('.details-text').toggleClass("active");
		});
	}

	detailsIcon();

// favorite icon

	// $('.js-active').click( function(e) {
	// 	e.preventDefault();
	// 	$(this).toggleClass('active');
	// });

// select2

	$('.select2').select2({
		width: '100%',
		minimumResultsForSearch: Infinity,
		allowClear: true,
		dropdownCssClass: 'select-new-dropdown'
	});

	$('.select-row .select2').select2({
		width: '100%',
		minimumResultsForSearch: Infinity,
		allowClear: true,
		dropdownCssClass: 'select-row-dropdown',
		dropdownAutoWidth: true
	});

	$('.form_transparent .select2').select2({
		width: '100%',
		minimumResultsForSearch: Infinity,
		allowClear: true,
		dropdownCssClass: 'select-transparent-dropdown'
	});


	$('.select2').on('select2:selecting', function(e) {
		$(this).removeClass("error");
	});

	// $('.credit-form .select2[name="brands"]').on('select2:selecting', function(e) {
	// 	$(".credit-form__no-choice").addClass("hide");
	// 	$(".credit-form__choice").addClass("show");
	// 	setTimeout(function () {
	// 		progressView();
	// 	}, 0);
	// });

// checkbox

	$('.checkbox input').change(function(){
		$(this).closest(".checkbox").toggleClass('changed');
	});


// rating circle

	function progressView(){
		var diagramBox = document.querySelectorAll('.diagram');
		diagramBox.forEach((box) => {
			let deg = (360 * box.dataset.rating / 5) + 180;
			if(box.dataset.rating >= 2.5){
				box.classList.add('over_50');
			}else{
				box.classList.remove('over_50');
			}
			box.querySelector('.diagram__piece_right').style.transform = 'rotate('+deg+'deg)';
		});
	}


// tabs

	$('.simple-tabs__button').click(function(e){
		e.preventDefault();
		var index=$(this).index();
		var item=$(this).closest('.simple-tabs').find('.simple-tabs__item').eq(index);

		$(this).closest('.simple-tabs').find('.simple-tabs__item').removeClass('active');
		item.addClass('active');
		$(this).closest('.simple-tabs').find('.simple-tabs__button').removeClass('active');
		$(this).addClass('active');

		$(this).closest('.simple-tabs').find(".simple-tabs__item").each(function() {

			$(this).find('.simple-tabs__button').eq(index).addClass('active');
		});
	});

	setTimeout(function(){
		ww=0;
		window.dispatchEvent(new Event('resize'));
		if (typeof(Event) === 'function') {
			window.dispatchEvent(new Event('resize'));
		} else {
			var evt = window.document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
		}
	},0);

	function checkCookies(){
		let cookieDate = localStorage.getItem('cookieDate');
		let cookieNotification = document.getElementById('cookie_notification');
		let cookieBtn = cookieNotification.querySelector('.cookie_accept');

		// Если записи про кукисы нет или она просрочена на 1 год, то показываем информацию про кукисы
		if( !cookieDate || (+cookieDate + 31536000000) < Date.now() ){
			cookieNotification.classList.add('show');
		}

		// При клике на кнопку, в локальное хранилище записывается текущая дата в системе UNIX
		cookieBtn.addEventListener('click', function(){
			localStorage.setItem( 'cookieDate', Date.now() );
			cookieNotification.classList.remove('show');
		})
	}
	checkCookies();

});
