$(document).ready(function(){
    $('.info_menu_item').on('click', function() {
        let entity = $(this).data('link');

        $(`.info_menu_item`).each(function() {
            $(this).removeClass('active');
        })

        $(this).toggleClass('active');

        $(`.info_column_right div`).each(function() {
            $(this).removeClass('active');
        })
        $(`.info_column_right .${entity}`).toggleClass('active');
    })
})

