$(document).ready(function(){

    $('.sort-line a').on('click', function (e)
    {
        e.preventDefault();

        let currentUrl = window.location.href;


        let url = `/api/cars/get_filters/`;
        if ('undefined' !== typeof  window._route)
            url = window._route;



        url = url.replace(/\s/g, '');
        let filterFormData = $(this).data('params');
        filterFormData = filterFormData.replaceAll('amp;', '');
        let order = $(this).data('order');
        let type = $(this).data('type');
        let additionalParams = $(this).data('add');
        window._skip = 0;
        filterFormData = filterFormData + '&' + type + '=' + order + '&' + additionalParams;
        window._filterFormData = filterFormData;
        let _this = $(this);
        $.ajax({
            method: "GET",
            url: url,
            data: filterFormData,
            success: function (response) {
                $('span#collection__button').remove();
                if (response.data && response.data.view === '')
                    return;
                $('div.loader').remove();
                window._flushResponse = true;

                $('.sort-line a').removeClass('active');
                $('.sort-line a').removeClass('ascending');
                $(_this).addClass('active');
                let newOrder = (order === 'asc') ? 'desc' : 'asc';
                $(_this).data('order', newOrder)

                if (newOrder === 'asc') $(_this).addClass('ascending');

                let append = $(_this).closest('.s_append');
                $(append).find('.s_change').remove();
                $(append).append(response.data.view);

                // window.initCarsSwiper()
                $('.compilation-buttons > .active').trigger('click');
                initSwiper();
                initSwiperCarPlate();
                _jsfav_init();
                detailsIcon();
            }
        })
    })
})
