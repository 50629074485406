window.$ = window.jQuery = require('jquery');

require('./lib/jquery.fancybox.min.js')
require('./lib/simplebar')
require('./lib/select2.full.js')
require('./lib/jquery.maskedinput.js')
require('./lib/jquery.validate.js')

require('./scripts/common.js')

require('./scripts/common_extra.js')

require('./scripts/info')

require('./scripts/forms.js')
require('./scripts/sliders.js')

require('./filters')
require('./scripts/initPopups.js')
require('./scripts/credit')
require('./scripts/favorites')
require('./scripts/sortLineAjax')


