window._jsfav_init = function () {
    $('.js-fav').click( function(e) {
        e.preventDefault();
        let id = $(this).data('id');
        let el = $(this);
        $.ajax({
            method: "GET",
            url: `/api/cars/favorites/${id}` ,
            success: function (res) {
                $('.js-fav[data-id='+id+']').toggleClass('active');
                let count = parseInt(res.data);
                $('.fav-icon__num').text(count);
                if (count > 0)
                    $('.header__fav').addClass('active');
                else
                    $('.header__fav').removeClass('active');
            }
        })
    });
}

window._jscompare_init = function () {
    $('.js-compare').click( function(e) {
        e.preventDefault();
        let id = $(this).data('id');
        let el = $(this);
        $.ajax({
            method: "GET",
            url: `/api/cars/compares/${id}` ,
            success: function (res) {
                $('.js-compare[data-id='+id+']').toggleClass('active');
                let count = parseInt(res.data);
                $('.compare-icon__num').text(count);
                if (count > 0)
                    $('.header__compare').addClass('active');
                else
                    $('.header__compare').removeClass('active');
            }
        })
    });
}
$(document).ready(function (){
    _jsfav_init();
    _jscompare_init();
})