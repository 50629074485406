$(document).ready(function () {


    function template(data) {
        return data.html;
    }

    document.addEventListener("loadComboboxItems", function (event) {
        select2Init(event.detail);
    });

    function select2Init(data = null) {
        let combobox = $(".select2-template").select2({
            width: '100%',
            minimumResultsForSearch: Infinity,
            allowClear: true,
            dropdownCssClass: 'select-new-dropdown',
            selectOnClose: true,
            data: data,
            templateResult: template,
            escapeMarkup: function (m) {
                return m;
            }
        });
        if (data !== null)
            combobox.val(data[0].id).trigger('change');
    }

    select2Init();

    $(".select2-template").on('change', function (e) {
        let data = $(this).select2('data');
        if ('undefined' !== typeof data && 'undefined' !== typeof data[0]) {
            setPlate(data[0].plateHtml)
        }
    })

    $('select[name="percent"]').on('change', function (){
        creditCalc();
    })

    $('select[name="months"]').on('change', function (){
        creditCalc();
    })


    function setPlate(plateHtml) {
        $('.select2_item').replaceWith(plateHtml);
        creditCalc();
        progressView();
    }

    document.addEventListener("loadComboboxItems", function (event) {
        select2Init(event.detail);
    });

    $('.select2-template').on('select2:selecting', function (e) {
        $(this).removeClass("error");
    });

    function creditCalc() {
        let percent = parseInt($('select[name="percent"]').val());
        let months = parseInt($('select[name="months"]').val());
        let carPrice = parseInt($('#car_price').val());
        if (isNaN(percent) || isNaN(months))
            return;

        let initialPayment = carPrice * percent / 100;
        let priceToPay = carPrice - initialPayment;
        let fullCreditPrice = priceToPay * 0.9 * 1.12;
        let montly = Math.ceil(fullCreditPrice / months);

        if (isNaN(montly))
            return;

        $('#montly_pay').text(montly.toLocaleString('ru-RU') + ' руб/мес');
    }

    function progressView(){
        var diagramBox = document.querySelectorAll('.diagram');
        diagramBox.forEach((box) => {
            let deg = (360 * box.dataset.rating / 5) + 180;
            if(box.dataset.rating >= 2.5){
                box.classList.add('over_50');
            }else{
                box.classList.remove('over_50');
            }
            box.querySelector('.diagram__piece_right').style.transform = 'rotate('+deg+'deg)';
        });
    }
})