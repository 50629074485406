$(document).ready(function(){

// forms validation and mask

	function reachGoals() {

		if (typeof(window.mailru_id) !== "undefined" && typeof(window.mailru_target_name) !== "undefined")
		{
			var _tmr = window._tmr || (window._tmr = []);
			_tmr.push({"type":"reachGoal","id":window.mailru_id,"value":1,"goal":window.mailru_target_name});
		}

		if (typeof(ym) === "undefined")
			return;

        ym(window.metrica_id, 'reachGoal', window.target_name);
    }

	$('input[type="tel"]').mask("+7(nnn)nnn-nnnn",{autoclear: false});

	$('input[type="tel"]').on('paste', function (e) {
		var text = e.originalEvent.clipboardData.getData('text');
		text = text.trimLeft();
		text = text.replace(/^\+/, '');
		text = text.replace(/^7/, '');
		text = text.replace(/^8/, '');
		e.target.value = text;
	});


	jQuery.validator.addMethod("requiredselect", function(value, element, arg){
		return arg != value;
	}, "Выберите один из вариантов");

	jQuery.validator.addMethod('checkMask', function (value, element) {
        return this.optional(element) || /\+\d{1}\(\d{3}\)\d{3}-\d{4}/g.test(value);
    }, 'Введите верный номер');

	jQuery.validator.addMethod("notnumbers", function(value, element) {
		return this.optional(element) || /^[a-zа-яё\-\s]+$/i.test(value);
	}, "Введите только буквы");

	$('.popup .wpcf7-form').each(function() {
		var form = $(this);
		var formBtn = form.find('button[type="submit"]');
		form.validate({
			errorPlacement: $.noop,
			rules: {
				info: 'required',
				term: 'required',
				sum: 'required',
				brand: 'required',
				model: 'required',
				mileage: 'required',
				release: 'required',
				price: 'required',
				date: 'required',
				name: {
					required: true,
					notnumbers: true
				},
				mail: {
				  required: true,
				  email: true,
				},
				phone: {
					required: true,
					checkMask: true,
				},
				brands: {
					requiredselect: "",
				},
				models: {
					requiredselect: "",
				},
				equipment: {
					requiredselect: "",
				},
				carbody: {
					requiredselect: "",
				},
			},
			submitHandler: function() {
				var formID = $(form).attr('id');
				var formNm = $('#' + formID);
				let url = formID.replace("-popup", "")
				formBtn.addClass('loading').prop('disabled', true);
				$.ajax({
					url: "/api/offers/" + url,
					method: "POST",
					data: form.serialize(),
				}).always(function(res){
					if(res.success)
					{
						reachGoals();
						$.fancybox.close();
						$.fancybox.open({
							src: '#thx-popup',
							type: 'inline',
						});
					}
					else
					{
						$.fancybox.open({
							src: '#err-popup',
							type: 'inline',
						});
					}
				});
			}
		});
	});

	$('.wpcf7_validate .wpcf7-form').each(function() {
		var form = $(this);
		var formBtn = form.find('button[type="submit"]');
		form.validate({
			ignore: 'input[type=hidden]',
			errorPlacement: $.noop,
			rules: {
				info: 'required',
				term: 'required',
				brand: 'required',
				model: 'required',
				mileage: 'required',
				release: 'required',
				price: 'required',
				date: 'required',
				percent: 'required',
				months: 'required',
				name: {
					required: true,
					notnumbers: true
				},
				mail: {
					required: true,
					email: true,
				},
				phone: {
					required: true,
					checkMask: true,
				},
				brands: {
					requiredselect: "",
				},
				models: {
					requiredselect: "",
				},
				version: {
					requiredselect: "",
				},
				equipment: {
					requiredselect: "",
				},
				carbody: {
					requiredselect: "",
				}
			},
			submitHandler: function() {
				var formID = $(form).attr('id');
				var formNm = $('#' + formID);
				formBtn.addClass('loading');
				formBtn.prop('disabled', true);
				$.ajax({
					url: "/api/offers/" + formID,
					method: "POST",
					data: formNm.serialize(),
				}).always(function(res){
					if(res.success){
						reachGoals();
						formBtn.removeClass('loading');
						formBtn.prop('disabled', false);
						$.fancybox.open({
							src: '#thx-popup',
							type : 'inline',
						});
					}else{
						formBtn.removeClass('loading');
						formBtn.prop('disabled', false);
						$.fancybox.open({
							src: '#err-popup',
							type : 'inline',
						});
					}
				});
			}
		});
	});
});
