window.showTradeInPopup = function(car_id, car_name) {
    $.fancybox.open({
        src: '#trade-in-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('.popup__car-id').val(car_id);
                $('.popup__car_name').text(car_name);
            }
        }
    });
}

window.showCreditPopup = function(car_id, car_name, car_details, car_price) {
    $.fancybox.open({
        src: '#credit-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('.popup__car-id').val(car_id);
                $('.popup__car_name').text(car_name);
                $('.popup__car_details').text(car_details);
                $('.popup__car_price').text(car_price);
            }
        }
    });
}

window.showReservePopup = function(car_id, car_name, car_details, car_price)
{
    $.fancybox.open({
        src: '#reserve-popup',
        type : 'inline',
        opts : {
            beforeShow : function( instance, slide ) {
                $('.popup__car-id').val(car_id);
                $('.popup__car_name').text(car_name);
                $('.popup__car_details').text(car_details);
                $('.popup__car_price').text(car_price);
            }
        }
    });
}

window.showInstallmentPopup = function(car_id, car_name) {
    $.fancybox.open({
        src: '#installment-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('.popup__car-id').val(car_id);
                $('.popup__car_name').text(car_name);
            }
        }
    });
}