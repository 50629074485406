import Swiper, {
	SwiperOptions,
	Navigation,
	Pagination,
	Scrollbar,
	EffectCoverflow
} from 'swiper';

Swiper.use([
	Navigation,
	Pagination,
	Scrollbar,
	EffectCoverflow
]);

$(document).ready(function(){

// sliders
	window.initSwiperCarPlate = function () {
		var swiperCarPlate = new Swiper ('.swiper-car-plate .swiper-container', {
			observer: true,
			observeParents: true,
			slidesPerView: "1",
			pagination: {
				el: '.swiper-car-plate .swiper-pagination',
				clickable: true,
			},
		});

		$('.swiper-car-plate .swiper-pagination-bullet').hover(function() {
			$(this).trigger("click");
		});

		$('.swiper-car-plate .swiper-pagination-bullet').click(function(e) {
			e.stopPropagation();
		});
	}

	initSwiperCarPlate();

	$('.swiper-cars .swiper-container').each(function(){
		var swiperCars = new Swiper ('.swiper-cars .swiper-container', {
			observer: true,
			observeParents: true,
			slidesPerView: "auto",
			spaceBetween: 15,
			direction: 'horizontal',
			nested: true,
		});
	});

	window.initSwiper = function()
	{
		let swiperFullPlate = undefined;
		swiperFullPlate = new Swiper ('.swiper-full-plate .swiper-container', {
			slidesPerView: "1",
			pagination: {
				el: '.swiper-full-plate .swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-full-plate .swiper-button-next',
				prevEl: '.swiper-full-plate .swiper-button-prev',
			},
		});

		$('.swiper-full-plate .swiper-pagination-bullet').hover(function() {
			$(this).trigger("click");
		});

		$('.swiper-full-plate .swiper-pagination-bullet').click(function(e) {
			e.stopPropagation();
		});

	}

	initSwiper();




	var galleryThumbs = new Swiper( ".gallery-thumbs", {
		slidesPerView: "auto",
		navigation: {
			nextEl: '.gallery-thumbs .swiper-button-next',
			prevEl: '.gallery-thumbs .swiper-button-prev',
		},
	});

	var galleryTop = new Swiper( '.gallery-top', {
		spaceBetween: 0,
		slidesPerView: "auto",
		navigation: {
			nextEl: '.swiper-gallery>.swiper-button-next',
			prevEl: '.swiper-gallery>.swiper-button-prev',
		},
		thumbs: {
			swiper: galleryThumbs,
		},
		breakpoints: {
			640: {
				slidesPerView: 1,
			},
		}
	});

	var advantagesSwiper = undefined;
	function initAdvantagesSwiper() {
		var screenWidth = $(window).outerWidth();
		if ( (screenWidth < (1280)) && (advantagesSwiper == undefined)) {
			advantagesSwiper = new Swiper ('.swiper-advantages .swiper-container', {
				slidesPerView: 'auto',
				spaceBetween: 15,
				navigation: {
					nextEl: '.swiper-advantages .swiper-button-next',
					prevEl: '.swiper-advantages .swiper-button-prev',
				},
			})
		} else if ((screenWidth > 1279) && (advantagesSwiper != undefined)) {
			advantagesSwiper.destroy();
			advantagesSwiper = undefined;
			$('.swiper-advantages .swiper-wrapper').removeAttr('style');
			$('.swiper-advantages .swiper-slide').removeAttr('style');
		}
	}
	initAdvantagesSwiper();

	$(window).resize(function() {
		initAdvantagesSwiper();
	});

	var carsMobSwiper = undefined;
	function initCarsMobSwiper() {
		var screenWidth = $(window).outerWidth();
		if ( (screenWidth < (1366)) && (carsMobSwiper == undefined)) {
			carsMobSwiper = new Swiper ('.swiper-cars-mob .swiper-container', {
				slidesPerView: 'auto',
				spaceBetween: 15,
				navigation: {
					nextEl: '.swiper-cars-mob .swiper-button-next',
					prevEl: '.swiper-cars-mob .swiper-button-prev',
				}
			})
		} else if ((screenWidth > 1365) && (carsMobSwiper != undefined)) {
			carsMobSwiper.destroy();
			carsMobSwiper = undefined;
			$('swiper-cars-mob .swiper-wrapper').removeAttr('style');
			$('swiper-cars-mob .swiper-slide').removeAttr('style');
		}
	}
	initCarsMobSwiper();

	var swiperCompare = new Swiper ('.swiper-compare-container', {
		observer: true,
		observeParents: true,
		slidesPerView: "auto",
		spaceBetween: 15,
		navigation: {
			nextEl: '.compare-screen .swiper-button-next',
			prevEl: '.compare-screen .swiper-button-prev',
		},
		breakpoints: {
			1280: {
				slidesPerView: 3,
			},
		}
	});


	$(window).resize(function() {
		initCarsMobSwiper();
	});

	var swiperPartners = new Swiper ('.swiper-partners .swiper-container', {
		slidesPerView: "1",
		navigation: {
			nextEl: '.partners-screen .swiper-button-next',
			prevEl: '.partners-screen .swiper-button-prev',
		},
		breakpoints: {
			375: {
				slidesPerView: 2,
			},
			640: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
		}
	});

	var swiperPartnersSmall = new Swiper ('.swiper-partners-small .swiper-container', {
		slidesPerView: "1",
		spaceBetween: 0,
		navigation: {
			nextEl: '.swiper-partners-small .swiper-button-next',
			prevEl: '.swiper-partners-small .swiper-button-prev',
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 2,
			},
			1366: {
				slidesPerView: 2,
			},
		}
	});

	var swiperPartnersRows = new Swiper ('.swiper-partners-rows .swiper-container', {
		slidesPerView: 1,
		slidesPerColumn: 2,
		spaceBetween: 0,
		navigation: {
			nextEl: '.swiper-partners-rows .swiper-button-next',
			prevEl: '.swiper-partners-rows .swiper-button-prev',
		},
		breakpoints: {
			375: {
				slidesPerView: 2,
				slidesPerColumn: 2,
			},
		}
	});

});